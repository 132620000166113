import React, { useEffect, useState } from 'react';

function WeatherInfo() {
  const [weather, setWeather] = useState({
    temperature: '20°C',
    precipitation: '0mm',
    pm10: '30㎍/m³',
    pm2_5: '15㎍/m³',
  });

  // 실제 데이터 가져오기 로직 추가 가능
  useEffect(() => {
    // fetch weather data from API and set state
  }, []);

  return (
    <div>
      <h2>현재 날씨 정보</h2>
      <p>기온: {weather.temperature}</p>
      <p>강수량: {weather.precipitation}</p>
      <p>미세먼지: {weather.pm10}</p>
      <p>초미세먼지: {weather.pm2_5}</p>
    </div>
  );
}

export default WeatherInfo;
